.brightid-nft-mint {
    --statusCompleteIconBorderWidth: 7px;
    --statusCompleteIconHeight: 24px;
    --statusCompleteIconWidth: 12px;

    --statusIncompleteIconHeight: 7px;

    --statusIconSize: 60px;

    --fontSize: 16px;
    --paddingSize: 10px;
    --borderRadius: 10px;

    --stepBackgroundColor: #fcfcfc;
    --stepTextColor: #111;

    --stepDescriptionBackgroundColor: #e5e5e5;
    --stepDescriptionLinkColor: #008177;
    --stepDescriptionLinkHoverColor: #009f93;

    --stepButtonBackgroundColor: #282c34;
    --stepButtonBackgroundColorHover: #001e36;
    --stepButtonColor: #fff;

    --statusInactiveColor: #bbb;

    --stepMessageTextColor: #e5e5e5;

    --stepResponseBackgroundColor: #001e36;
    --stepResponseTextColor: #fff;
    --stepResponseSuccessBackgroundColor: #080;
    --stepResponseSuccessTextColor: #fff;
    --stepResponseErrorBackgroundColor: #800;
    --stepResponseErrorTextColor: #fff;
    --stepResponseFontSize: 12.9px;

    --statusCompleteBackgroundColor: #bdd9a1;
    --statusCompleteIconColor: #080;

    --statusIncompleteBackgroundColor: #b13f3f;
    --statusIncompleteIconColor: #fff;
}

/* Buttons */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint {
    padding: var(--paddingSize);
    font-size: var(--fontSize);
}

.brightid-nft-mint-step__button {
    align-items: center;
    background: var(--stepButtonBackgroundColor);
    border: 0;
    border-radius: var(--borderRadius);
    color: var(--stepButtonColor);
    cursor: pointer;
    display: flex;
    font-weight: bold;
    justify-content: center;
    margin: 0 auto;
    min-width: 75px;
    padding: var(--paddingSize);
}

.brightid-nft-mint-step__button:hover {
    background: var(--stepButtonBackgroundColorHover);
}

.brightid-nft-mint-step__button + .brightid-nft-mint-step__button {
    margin-top: 2px;
}

.brightid-nft-mint-step__button--small {
    padding: 6px 10px;
    min-width: 0;
    margin: 2px 0;
}

.brightid-nft-mint-step__button[disabled] {
    opacity: 0.3;
    cursor: default;
}

/* Form */
/* -------------------------------------------------------------------------- */
.brightid-nft-mint-step__input {
    box-sizing: border-box;
    font-size: 12px;
    width: 100%;
    padding: 5px;
}

/* Step */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-step {
    background: var(--stepBackgroundColor);
    border-radius: var(--borderRadius);
    color: var(--stepTextColor);
    margin-bottom: var(--paddingSize);
    padding: var(--paddingSize);
}

.brightid-nft-mint-step__main {
    align-items: center;
    display: flex;
    justify-content: center;
}

.brightid-nft-mint-step__status {
    flex: 0 0 var(--statusIconSize);
    padding-right: var(--paddingSize);
}

.brightid-nft-mint-step__header {
    flex: 1;
}

.brightid-nft-mint-step__action {
    flex: 0 0 75px;
    padding-left: var(--paddingSize);
}

.brightid-nft-mint-step__heading {
    font-size: 18px;
    margin: 0;
    padding-left: calc(var(--paddingSize) / 2);
}

.brightid-nft-mint-step__action--app-store {
    flex: 0 0 125px;
}

.brightid-nft-mint-step__app-store-image {
    max-width: 100%;
}

.brightid-nft-mint-step__action-prevented {
    display: block;
    font-size: 0.8em;
    text-align: center;
}

/* Description */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-step__description {
    background: var(--stepDescriptionBackgroundColor);
    border-radius: var(--borderRadius);
    margin-top: var(--paddingSize);
    padding: var(--paddingSize);
}

.brightid-nft-mint-step__description-link,
.brightid-nft-mint-step__description-link:visited {
    color: var(--stepDescriptionLinkColor);
}

.brightid-nft-mint-step__description-link:hover,
.brightid-nft-mint-step__description-link:active {
    color: var(--stepDescriptionLinkHoverColor);
}

.brightid-nft-mint-step__description-p {
    margin: 0;
}

.brightid-nft-mint-step__description-p
    + .brightid-nft-mint-step__description-p {
    margin-top: 1em;
}

.brightid-nft-mint-step__description-p--2col-img {
}

.brightid-nft-mint-step__description-p--2col-img img {
    width: 48%;
}

.brightid-nft-mint-step__description-p--2col-img img + img {
    margin-left: 4%;
}

.brightid-nft-mint-step__description-button-container {
    text-align: center;
}

.brightid-nft-mint-step__description-qrcode-container {
    text-align: center;
}

.brightid-nft-mint-step__description-qrcode-container > svg {
    border: 10px solid #fff;
    border-radius: var(--borderRadius);
}

.brightid-nft-mint-step__description-wallet-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.brightid-nft-mint-step__description-wallet-address {
    display: block;
    font-size: 12px;
}

.brightid-nft-mint-step__description--no-header {
    margin-top: 0;
}

.brightid-nft-mint-step__important {
    color: var(--statusCompleteIconColor);
}

.brightid-nft-mint-step__description-reset {
    display: block;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
    margin-top: 10px;
}

.brightid-nft-mint-step--mobile {
    display: none;
}

/* @media only screen and (hover: none) and (pointer: coarse) {
    .brightid-nft-mint-step--mobile {
        display: block;
    }

    .brightid-nft-mint-step--desktop {
        display: none;
    }
} */

@media (any-pointer: coarse) {
    .brightid-nft-mint-step--mobile {
        display: block;
    }

    .brightid-nft-mint-step--desktop {
        display: none;
    }
}

/* Interaction Info and Errors */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-step__loading-icon {
    display: inline-block;
    height: 20px;
    position: relative;
    width: 20px;
}

.brightid-nft-mint-step__loading-icon div {
    animation: brightid-nft-mint-step__loading-icon 1.2s
        cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 2px solid #fff;
    border-color: #fff transparent transparent transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 16px;
    margin: 2px;
    position: absolute;
    width: 16px;
}

.brightid-nft-mint-step__loading-icon div:nth-child(1) {
    animation-delay: -0.45s;
}

.brightid-nft-mint-step__loading-icon div:nth-child(2) {
    animation-delay: -0.3s;
}

.brightid-nft-mint-step__loading-icon div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes brightid-nft-mint-step__loading-icon {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Status Response */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-step__response {
    align-items: center;
    background: var(--stepResponseBackgroundColor);
    border-radius: var(--borderRadius);
    color: var(--stepResponseTextColor);
    display: flex;
    font-size: var(--stepResponseFontSize);
    margin-top: var(--paddingSize);
    padding: var(--paddingSize);
}

.brightid-nft-mint-step__response-loading-icon {
    margin-right: 10px;
}

.brightid-nft-mint-step__response-message {
    flex: 1;
}

.brightid-nft-mint-step__response--success {
    background: var(--stepResponseSuccessBackgroundColor);
    color: var(--stepResponseSuccessTextColor);
}

.brightid-nft-mint-step__response--error {
    background: var(--stepResponseErrorBackgroundColor);
    color: var(--stepResponseErrorTextColor);
    word-break: break-all;
}

.brightid-nft-mint-step__response-link {
    color: var(--stepResponseTextColor);
    text-decoration: underline;
}

/* Status Icon */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-step__status-icon {
    background: #aaa;
    border-radius: var(--borderRadius);
    height: var(--statusIconSize);
    position: relative;
    width: var(--statusIconSize);
}

/* Status Icon - Incomplete */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-step--incomplete .brightid-nft-mint-step__status-icon {
    background: var(--statusIncompleteBackgroundColor);
}

.brightid-nft-mint-step--incomplete .brightid-nft-mint-step__status-icon:before,
.brightid-nft-mint-step--incomplete .brightid-nft-mint-step__status-icon:after {
    background-color: var(--statusIncompleteIconColor);
    bottom: 0;
    content: "\00a0";
    display: inline-block;
    height: var(--statusIncompleteIconHeight);
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 80%;
}

.brightid-nft-mint-step--incomplete
    .brightid-nft-mint-step__status-icon:before {
    transform: rotate(45deg);
}

.brightid-nft-mint-step--incomplete .brightid-nft-mint-step__status-icon:after {
    transform: rotate(-45deg);
}

/* Status Icon - Complete */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-step--complete .brightid-nft-mint-step__status-icon {
    background: var(--statusCompleteBackgroundColor);
}

.brightid-nft-mint-step--complete .brightid-nft-mint-step__status-icon:after {
    border-bottom: var(--statusCompleteIconBorderWidth) solid
        var(--statusCompleteIconColor);
    border-right: var(--statusCompleteIconBorderWidth) solid
        var(--statusCompleteIconColor);
    bottom: 0;
    content: "\00a0";
    display: inline-block;
    height: var(--statusCompleteIconHeight);
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: -10px;
    transform: rotate(45deg);
    width: var(--statusCompleteIconWidth);
}

/* Status Changes */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-step--complete .brightid-nft-mint-step__action,
.brightid-nft-mint-step--Xinactive .brightid-nft-mint-step__action {
    display: none;
}

.brightid-nft-mint-step--connect .brightid-nft-mint-step__action {
    display: block;
}

.brightid-nft-mint-step--complete
    .brightid-nft-mint-step__description--action-hide-on-complete {
    display: none;
}

.brightid-nft-mint-step--inactive {
    opacity: 0.5;
}

/* Supply */
/* -------------------------------------------------------------------------- */

.brightid-nft-mint-supply {
    background: #282c34;
    position: sticky;
    top: 0;
    border-radius: 0 0 var(--borderRadius) var(--borderRadius);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    z-index: 1;
    margin: 0 calc(-1 * (var(--paddingSize) / 2));
}

.brightid-nft-mint-supply--sold-out {
}
